import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePipe } from './image.pipe';
import { PhoneModalComponent } from './phone-modal/phone-modal.component';
import { PhoneFormComponent } from './phone-modal/phone-form/phone-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FooterComponent } from './footer/footer.component';
import { TopRightComponent } from './top-right/top-right.component';
import { ServicePickModalComponent } from './service-pick-modal/service-pick-modal.component';
import { PlacePickModalComponent } from './place-pick-modal/place-pick-modal.component';
import { DatePickModalComponent } from './date-pick-modal/date-pick-modal.component';
import { HoursPickModalComponent } from './hours-pick-modal/hours-pick-modal.component';
import { SearchByNameModalComponent } from './search-by-name-modal/search-by-name-modal.component';
import { ProviderFormComponent } from './provider-form/provider-form.component';
import { ProviderModalComponent } from './provider-modal/provider-modal.component';
import { WorkingHoursComponent } from './working-hours/working-hours.component';
import { WorkingHoursRuleComponent } from './working-hours/working-hours-rule/working-hours-rule.component';
import { CollaborationTypeModalComponent } from './collaboration-type-modal/collaboration-type-modal.component';
import { CompanyFormComponent } from './company-form/company-form.component';
import { AngularPhoneUtilsLibModule } from 'angular-phone-utils-lib';
import { RouterModule } from '@angular/router';

import { 
  MatDatepickerModule, 
  MatNativeDateModule
} from '@angular/material';
import { PpPhonePipe } from './pp-phone.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ImagePipe,
    PhoneModalComponent,
    PhoneFormComponent,
    FooterComponent,
    TopRightComponent,
    ServicePickModalComponent,
    PlacePickModalComponent,
    DatePickModalComponent,
    HoursPickModalComponent,
    SearchByNameModalComponent,
    ProviderFormComponent,
    ProviderModalComponent,
    CollaborationTypeModalComponent,
    CompanyFormComponent,
    WorkingHoursComponent,
    WorkingHoursRuleComponent,
    PpPhonePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AngularPhoneUtilsLibModule,
    RouterModule,
    TranslateModule
  ],
  exports: [
    CommonModule,
    ImagePipe,
    PhoneModalComponent,
    PhoneFormComponent,
    FooterComponent,
    TopRightComponent,
    ProviderFormComponent,
    CompanyFormComponent,
    PpPhonePipe,
    TranslateModule,
    WorkingHoursComponent,
    WorkingHoursRuleComponent
  ],
  entryComponents: [
    ServicePickModalComponent,
    PlacePickModalComponent,
    DatePickModalComponent,
    HoursPickModalComponent,
    SearchByNameModalComponent
  ]
})
export class SharedModule { }