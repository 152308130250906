import { Component, OnInit, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { SearchPreferences } from '../../core/models/search-preferences/search-preferences.model';
import { DatePeriod } from '../../core/models/search-preferences/date-period.model';
import { DatePeriodPickedOption } from '../../core/models/search-preferences/date-period.model';
import * as moment from 'moment';
import { NativeDateAdapter, DateAdapter } from '@angular/material';
import { formatDate } from '@angular/common';


//Set Monday as first day of week instead of Sunday (default used in mostly in US but not in Slovenia)
class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
   return 1;
  }
}

@Component({
  selector: 'app-date-pick-modal',
  templateUrl: './date-pick-modal.component.html',
  styleUrls: ['./date-pick-modal.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter }
  ]
})
export class DatePickModalComponent implements OnInit {

  @Input() searchPreferences: SearchPreferences;
  DatePeriodPickedOption = DatePeriodPickedOption;

  public dayOfWeek: number = new Date().getDay();
  public selected: Date = new Date();
  public startAt: Date = new Date();
  public minDate: Date = new Date();
  public maxDate: Date = new Date(new Date().setTime( this.minDate.getTime() + 14 * 86400000 ) );

  constructor(
    private modalCtrl: ModalController,
  ) { 
  }
 

  ngOnInit() {
  }

  onCancel() {
    this.modalCtrl.dismiss( null, 'cancel' );
  }

  onSelected( option: DatePeriodPickedOption, date?: Date ) {

    let dateFrom: moment.Moment;
    let dateTo: moment.Moment;

    switch( option ) {

      case DatePeriodPickedOption.Whenever:  {
        this.modalCtrl.dismiss( { selectedDatePeriod: null } , 'selected' );
      }

      case DatePeriodPickedOption.Today:  {
        dateFrom = moment().startOf('day');
        dateTo = moment(dateFrom).add(1, 'days').startOf('day');
        break;
      }

      case DatePeriodPickedOption.Tomorrow:  {
        dateFrom = moment().add(1, 'days').startOf('day');
        dateTo = moment(dateFrom).add(1, 'days').startOf('day');
        break;
      }

      case DatePeriodPickedOption.ThisWeek:  {
        
        dateFrom = moment().startOf('day');

        dateTo = moment(dateFrom).endOf('week').add(2, 'days').startOf('day');

        break;
      }

      case DatePeriodPickedOption.NextWeek:  {
        
        dateFrom = moment().endOf('week').add(2, 'days').startOf('day');

        dateTo = moment(dateFrom).endOf('week').add(2, 'days').startOf('day');

        break;
      }

      case DatePeriodPickedOption.Custom:  {

        if ( !date ) {
          throw new Error('Date should be set for custom picked date');
        }

        dateFrom = moment( date ).startOf('day');
        dateTo = moment(dateFrom).add(1, 'days').startOf('day');
        break;
      }

    }

    this.modalCtrl.dismiss( new DatePeriod( option, dateFrom, dateTo ), 'picked' );

  }

}