<ion-header class="ion-no-padding header header-modal -primary" mode="md">
  <ion-toolbar class="ion-no-padding" mode="md" color="primary">
    <ion-buttons slot="start">
      <ion-menu-toggle>
        <ion-button [routerLink]="['/']">
          <ion-icon size="small" name="home-sharp"></ion-icon>
        </ion-button>
        <ion-button class="language-button" (click)="translate.use('si')">
          SI
        </ion-button>
        <span class="language-delimiter">|</span>
        <ion-button class="language-button" (click)="translate.use('cro')">
          CRO
        </ion-button>
        <span class="language-delimiter">|</span>
        <ion-button class="language-button" (click)="translate.use('en')">
          EN
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-menu-toggle>
        <ion-button>
          <ion-icon [routerLink]="['/nastavitve']" size="medium" name="close-sharp"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-list>

  <!--<ion-item>
    <ion-label>Moje rezervacije</ion-label>
  </ion-item>

  <ion-item>
    <ion-label>Nastavitve</ion-label>
  </ion-item>

  -->

  <ng-container *ngIf="me$ | async as me; else loggedOut">
    <!--<ion-menu-toggle>
      <ion-item [routerLink]="['/moj-profil', 'moje-rezervacije']">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="calendar" button></ion-icon>
        <ion-label style="color:black;">Moje rezervacije</ion-label>
      </ion-item>
    </ion-menu-toggle>-->
    <ion-menu-toggle>
      <ion-item [routerLink]="['/koledar']">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="calendar" button></ion-icon>
        <ion-label style="color:black;">{{ 'MENU.CALENDAR' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle>
      <ion-item [routerLink]="['/statistika']">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="stats-chart" button></ion-icon>
        <ion-label style="color:black;">{{ 'MENU.STATISTICS' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle>
      <ion-item [routerLink]="['/dokumenti']">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="document-text" button></ion-icon>
        <ion-label style="color:black;">{{ 'MENU.DOCUMENTS' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle>
      <ion-item [routerLink]="['/nastavitve']">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="settings" button></ion-icon>
        <ion-label style="color:black;">{{ 'MENU.SETTINGS' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <!--<ion-menu-toggle>
      <ion-item [routerLink]="['/moj-profil']">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="person" button></ion-icon>
        <ion-label style="color:black;">Moj profil</ion-label>
      </ion-item>
    </ion-menu-toggle>-->
    <ion-menu-toggle>
      <ion-item (click)="logout()" [routerLink]="">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="power" button></ion-icon>
        <ion-label style="color:black;">{{ 'MENU.LOGOUT' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>

  </ng-container>



  <ng-template #loggedOut>

    <ion-menu-toggle>
      <ion-item [routerLink]="['/info', 'za-lastnike-salonov']">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="golf" button></ion-icon>
        <ion-label>{{ 'MENU.OWNERS' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>



    <ion-menu-toggle>
      <ion-item [routerLink]="['/auth', 'prijava']">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="person"></ion-icon>
        <ion-label style="color:black;">{{ 'MENU.LOGIN' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
  </ng-template>

  <ion-item style="--border-width: 0;">
    <ion-icon style="color:black; font-size: 20px;" slot="end" name="globe" button></ion-icon>
    <ion-label>{{ 'MENU.LANGUAGE' | translate }}</ion-label>
    <ion-select #langSelect interface="popover" [value]="translate.currentLang" (ionChange)="translate.use(langSelect.value)">
      <ion-select-option *ngFor="let lang of translate.getLangs()" [value]="lang">{{lang}}</ion-select-option>
    </ion-select>
  </ion-item>


</ion-list>