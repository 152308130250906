import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './core/guards/logged-in.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule),
    pathMatch: 'full'
  }, 
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    // TODO - remove, currently not active with no link to this module?
    path: 'moj-profil',
    canActivate: [ LoggedInGuard ],
    loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfilePageModule)
  },
  {
    path: 'nastavitve',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'koledar',
    canActivate: [ LoggedInGuard ],
    loadChildren: () => import('./scheduler/scheduler.module').then( m => m.SchedulerPageModule)
  },
  {
    // TODO - remove, currently not active with no link to this module?
    path: 'oceni',
    loadChildren: () => import('./rating/rating.module').then( m => m.RatingPageModule)
  },
  {
    path: 'nov-stilist',
    loadChildren: () => import('./worker-onboarding/worker-onboarding.module').then( m => m.WorkerOnboardingPageModule)
  },
  {
    path: 'nov-salon',
    loadChildren: () => import('./owner-onboarding/owner-onboarding.module').then( m => m.OwnerOnboardingPageModule)
  },
  {
    path: 'dokumenti',
    canActivate: [ LoggedInGuard ],
    loadChildren: () => import('./documents/documents.module').then( m => m.DocumentsPageModule)
  },
  {
    path: 'statistika',
    canActivate: [ LoggedInGuard ],
    loadChildren: () => import('./statistics/statistics.module').then( m => m.StatisticsPageModule)
  },

  { path: 'salon/:providerId/:shopSlug', loadChildren: () => import('./shop-detail/shop-detail.module').then(m => m.ShopDetailPageModule) },

  //shop-listing routes
  { path: ':location/:serviceType/:service', loadChildren: () => import('./shop-listing/shop-listing.module').then(m => m.ShopListingPageModule) },
  { path: ':location/:serviceType', loadChildren: () => import('./shop-listing/shop-listing.module').then(m => m.ShopListingPageModule) },
  { path: ':location', loadChildren: () => import('./shop-listing/shop-listing.module').then(m => m.ShopListingPageModule) },

  //not found routes redirect to landing page
  { path: '**', redirectTo: '' },
  {
    path: 'add-provider',
    loadChildren: () => import('./auth/add-provider/add-provider.module').then( m => m.AddProviderPageModule)
  },
  
  /*{
    path: 'collaboration-type-modal',
    loadChildren: () => import('./shared/collaboration-type-modal/collaboration-type-modal.module').then( m => m.CollaborationTypeModalPageModule)
  },*/
  

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false , preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }